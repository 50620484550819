

.Card-2{
    height: 600px;
    width: 450px;
    box-shadow: 0px 0px 10px 2px rgba(57, 56, 56, 0.75);
    translate: 1em -0.5em;
    border-radius: 5%;
    position: relative;
    margin-right: 3em;
    transition: all 0.5s ease-in-out;
    }
    
    .image{
    border-radius: 5%;
    height: 600px;
    width: 450px;
    }
    
    .Card-2:hover{
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    }
    
    .Card-2:hover .info{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    background-color: rgba(0,0,0,0.7);
    border-radius: 5%;
    padding: 1rem;
    transition: all 0.5s ease-in-out;
    }
    
    .info{
    display: none;
    }

@media (max-width: 600px) {
    .Card-2{
        height: 20rem;
        width: 15rem;
        translate: 0;
        margin: 20px;
       translate: 10%;
       margin-bottom: 50px;
       padding-right: 10px;
    }

    .image{
        height: 20rem;
        width: 15rem;
    }
    .background-video{
        display: none;
    }
    #myVideo{
        display: none;
    }
}
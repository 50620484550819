.valentine-app {
  text-align: center;
  margin-top: 50px;
  font-family: 'Arial', sans-serif;
}

.valentine-text {
  color: #ff69b4;
  font-size: 36px;
  margin-bottom: 20px;
}

.yes-button, .no-button {
  padding: 15px 30px;
  font-size: 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.yes-button {
  background-color: #4CAF50;
  color: white;
}

.yes-button:hover {
  background-color: #45a049;
}

.no-button {
  background-color: #f44336;
  color: white;
  position: absolute;
}

.no-button:hover {
  transform: translate(50px, 50px);
}

.calendar-container {
  margin-top: 20px;
}

.calendar {
  display: inline-block;
}



.Card{
    height: 600px;
    width: 450px;
    box-shadow: 0px 0px 10px 2px rgba(57, 56, 56, 0.75);
    translate: 1em -0.5em;
    border-radius: 5%;
    position: relative;
    margin-right: 3em;
}

.image{
    border-radius: 5%;
    height: 600px;
    width: 450px;
}

@media (max-width: 600px) {
    .Card{
        height: 20rem;
        width: 15rem;
        translate: 0;
        margin: 20px;
       translate: 10%;
       margin-bottom: 50px;
       padding-right: 10px;
    }

    .image{
        height: 20rem;
        width: 15rem;
    }
    .background-video{
        display: none;
    }
    #myVideo{
        display: none;
    }
}
/* General styles */
.date-app {
  text-align: center;
  margin-top: 50px;
  font-family: 'Arial', sans-serif;
}

.date-app h1 {
  color: #ff69b4;
  font-size: 36px;
  margin-bottom: 20px;
}

.date-app .yes-button, .date-app .no-button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: 150px;
  height: 50px;
}

.date-app .yes-button {
  background-color: #4CAF50;
  color: white;
}

.date-app .yes-button:hover {
  background-color: #45a049;
}

.date-app .no-button {
  background-color: #f44336;
  color: white;
  position: absolute;
}

.date-app .no-button:hover {
  transform: translate(50px, 50px);
}

/* iPhone SE */
@media (max-width: 375px) {
  .date-app h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .date-app .yes-button, .date-app .no-button {
    width: 100px;
    height: 40px;
    font-size: 14px;
  }
}

/* iPhone XR, iPhone 12 Pro, iPhone 14 Pro Max, iPhone 15 Pro Max */
@media (max-width: 430px) {
  .date-app h1 {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .date-app .yes-button, .date-app .no-button {
    width: 110px;
    height: 45px;
    font-size: 15px;
  }
}

/* Pixel 7 */
@media (max-width: 412px) {
  .date-app h1 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .date-app .yes-button, .date-app .no-button {
    width: 105px;
    height: 42px;
    font-size: 14px;
  }
}

/* Samsung Galaxy S8+, Samsung Galaxy S20 Ultra, Galaxy Z Fold 5 */
@media (max-width: 360px) {
  .date-app h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .date-app .yes-button, .date-app .no-button {
    width: 95px;
    height: 38px;
    font-size: 13px;
  }
}

/* iPad Mini, iPad Air, iPad Pro */
@media (min-width: 768px) and (max-width: 1024px) {
  .date-app h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .date-app .yes-button, .date-app .no-button {
    width: 130px;
    height: 50px;
    font-size: 16px;
  }
}

/* Surface Pro 7, Surface Duo */
@media (min-width: 540px) and (max-width: 960px) {
  .date-app h1 {
    font-size: 28px;
    margin-bottom: 18px;
  }
  .date-app .yes-button, .date-app .no-button {
    width: 120px;
    height: 45px;
    font-size: 15px;
  }
}

/* Asus Zenbook Fold, Samsung Galaxy A51/71 */
@media (min-width: 411px) and (max-width: 823px) {
  .date-app h1 {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .date-app .yes-button, .date-app .no-button {
    width: 110px;
    height: 45px;
    font-size: 15px;
  }
}

/* Nest Hub, Nest Hub Max */
@media (min-width: 600px) and (max-width: 1024px) {
  .date-app h1 {
    font-size: 28px;
    margin-bottom: 18px;
  }
  .date-app .yes-button, .date-app .no-button {
    width: 120px;
    height: 45px;
    font-size: 15px;
  }
}

.location-spoof {
    text-align: center;
    background: linear-gradient(to right, #00c6ff, #0072ff);
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .location-spoof h1 {
    margin-bottom: 20px;
  }
  
  .location-spoof p {
    font-size: 18px;
    margin: 5px 0;
  }
  
  .input-container {
    margin-top: 20px;
  }
  
  .input-container input {
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
  }
  
  .input-container button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #ff5722;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .input-container button:hover {
    background-color: #e64a19;
  }
  


.Card-1{
    height: 600px;
    width: 450px;
    box-shadow: 0px 0px 10px 2px rgba(57, 56, 56, 0.75);
    /* translate: 1em -1em; */
    border-radius: 5%;
    position: relative;
    margin: 5.5rem;
}

.image{
    border-radius: 5%;
    height: 600px;
    width: 450px;
}

@media (max-width: 600px) {
    .Card-1{
        height: 20rem;
        width: 15rem;
    }

    .image{
        height: 20rem;
        width: 15rem;
    }
    .background-video{
        display: none;
    }

    #myVideo{
        display: none;
    }
}
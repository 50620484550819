/* Base styling for the application */
.select-date-app {
  text-align: center;
  margin-top: 50px;
  font-family: 'Arial', sans-serif;
}

.select-date-app h2 {
  color: #ff69b4;
  font-size: 28px;
  margin-bottom: 20px;
}

.select-date-app form {
  display: inline-block;
  text-align: left;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 320px;
  margin: 0 auto;
}

.select-date-app label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.select-date-app input,
.select-date-app textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.select-date-app button {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.select-date-app button:hover {
  background-color: #45a049;
}

/* Styling for the review section */
.review-section {
  margin-top: 30px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  width: 100%; /* Ensure it takes full width */
}

.review-section h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.review-marquee {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px 0;
  position: relative;
  width: 100%;
}

.review-marquee-inner {
  display: inline-flex;
  animation: scrollLeft 30s linear infinite;
}

.review-item {
  display: inline-block;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  margin: 0 10px;
  width: 250px; /* Adjusted width */
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow: hidden;
  box-sizing: border-box;
  white-space: normal; /* Allow text to wrap within the container */
}

.review-item:hover {
  transform: scale(1.05);
}

.review-stars {
  color: gold;
  font-size: 1.1em;
  margin-bottom: 5px;
}

.review-item p {
  color: #333;
  font-size: 14px;
  margin: 5px 0 0 0;
}

.review-item strong {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  color: #4CAF50;
  word-wrap: break-word;
}

/* Continuous scrolling animation */
@keyframes scrollLeft {
  0% {
    transform: translateX(0); /* Start from the full left */
  }
  100% {
    transform: translateX(-50%); /* Move left to right continuously */
  }
}

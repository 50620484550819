.NavBar--Container {
    height: 170px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    box-shadow: 0px 0px 10px 2px rgba(57, 56, 56, 0.75);
    flex-direction: wrap;
    width: auto;
}

.Me--Image {
    height: 140px;
    box-shadow: 0px 0px 10px 2px rgba(57, 56, 56, 0.75);
    border-radius: 30%;
    margin: 15px;
    margin-right: 30rem;
}

li{
    display: inline-block;
    list-style-type: none;
    margin-right: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 15%;
    height: 30px;
    width: 70px;
    box-shadow: 0px 0px 10px 2px rgba(206, 206, 206, 0.75);
    margin: 2vw;
}

.Links{
    text-decoration: none;
    display: inline;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.RightComponent{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: fit-content;

}

a{
    text-decoration: none;
    color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    font-weight: 400;
    margin-top: 10%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

.Links a:hover, .Links a:active {
    text-decoration: underline;
    translate: 0 2px;
    transform: 150%;
}





@media (max-width: 600px) {

    .NavBar--Container {
      flex-wrap: wrap;
      height: auto;
    }
    .Me--Image {
      height: 22vh;
      margin: 10px;
      margin-right: 1px;
    }

    .Links{
        text-decoration: none;
        display: flex;
        flex-direction: column;
        margin: 10px;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    li{
        display: inline-block;
        list-style-type: none;
        margin-right: 20px;
        background-color: rgb(255, 255, 255);
        border-radius: 20%;
        height: 50px;
        width: 100px;
        box-shadow: 0px 0px 10px 2px rgba(206, 206, 206, 0.75);
    }
    video#myVideo{
        display: none;
    }

  }

.Welcome{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: black;
}

video#myVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  

h1{
    margin: 5%;
    margin-top: 15%;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  @media (max-width: 600px) {

    .Welcome{
        padding: 10%;
    }
    p,h1{
        text-align: center;
    }

    .background-video{
        display: none;
    }

    #myVideo{
        display: none;
    }

  }